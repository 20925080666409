@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
}

body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  background-attachment: fixed;
  animation: gradientBG 15s ease infinite;
  overflow-x: hidden;
  font-family: 'Jost', sans-serif;
}

#root {
  min-height: 100vh;
  width: 100%;
}

/* Add a white background to the content container for readability */

h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: clamp(18px, 3vw, 22px);
}

h4 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: clamp(16px, 2.5vw, 18px);
}

p {
  margin-bottom: 0px;
}

a {
  transition: opacity 0.2s ease;
}

a:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  p {
    margin-bottom: 0px;
  }
  
  h3 {
    margin-top: 25px;
    margin-bottom: 12px;
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes redPulsate {
  0% { color: white; }
  50% { color: #FF0000; }
  100% { color: white; }
}

.App {
  font-family: 'Jost', sans-serif;
}